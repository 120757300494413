import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import reducers from './reducers/menu';
import surveyReducers from './reducers/survey';

const rootReducer = combineReducers({
  menu: reducers,
  survey: surveyReducers,
});

const store = configureStore({
  reducer: rootReducer,
});

const { dispatch } = store;

export { store, dispatch };
