import React from 'react';
import Navigation from './Navigation/index';
import SimpleBar from '../../../../components/third-party/SimpleBar';

function DrawerContent() {
  return (
    <SimpleBar
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Navigation />
    </SimpleBar>
  );
}

export default DrawerContent;
