import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Profile from './Profile';
import MobileSection from './MobileSection';

function HeaderContent() {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <>
      <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }} />
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
}

export default HeaderContent;
