import React, { lazy } from 'react';
import Loadable from '../components/Loadable';
import { Navigate, Outlet } from 'react-router-dom';

const AuthLogin = Loadable(lazy(() => import('../pages/authentication/Login')));
const AuthForgot = Loadable(lazy(() => import('../pages/authentication/Forgot')));
const ForgotSuccess = Loadable(lazy(() => import('../pages/authentication/ForgotSuccess')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const AuthActivate = Loadable(lazy(() => import('../pages/authentication/Activate')));

const LoginRoutes = (isLogin) => ({
  path: '/',
  element: !isLogin ? <div><Outlet /></div> : <Navigate to="/" />,
  children: [{
    path: 'login',
    element: <AuthLogin />,
  },
  {
    path: 'forgot',
    element: <AuthForgot />,
  },
  {
    path: 'forgot-success/:secretEmail',
    element: <ForgotSuccess />,
  },
  {
    path: 'change-password',
    element: <ResetPassword />,
  },
  {
    path: 'auth/activate',
    element: <AuthActivate />,
  }],
}
);

export default LoginRoutes;
