import { createSlice } from '@reduxjs/toolkit';

const defaultQuestionnaire = {
  questionnaire: {
    status: 'inactive',
    id: '1',
    title: 'Untitled Form',
    questions: [
      {
        is_required: true,
        id: '1',
        title: 'Untitled Question',
        type: 1,
        order: 1,
        options: [
          {
            id: '1',
            title: 'Option',
            order: 0,
            data: {
              value: 'option',
            },
            option_logics: [],
          },
        ],
      },
    ],
  },
};

const initialState = {
  surveyState: [],
};

const survey = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    addQuestion: (state, action) => {
      const { indexQuestionnaire, question } = action.payload;
      state.surveyState[indexQuestionnaire].questionnaire.section.questions.push(question);
    },
    deleteQuestion: (state, action) => {
      const { indexQuestionnaire, idQuestion } = action.payload;
      state.surveyState[indexQuestionnaire].questionnaire.section.questions.splice(idQuestion, 1);
    },
    addOption: (state, action) => {
      const { indexQuestionnaire, idQuestion, option } = action.payload;
      state.surveyState[indexQuestionnaire].questionnaire.section.questions[
        idQuestion
      ]?.options.push(option);
    },
    deleteOptionSurvey: (state, action) => {
      const { idQuestion, idOption, indexQuestionnaire } = action.payload;
      state.surveyState[indexQuestionnaire].questionnaire.section.questions[
        idQuestion
      ]?.options.splice(idOption, 1);
    },
    saveQuestionSurvey: (state, action) => {
      const { indexQuestionnaire, idQuestion, question } = action.payload;
      state.surveyState[indexQuestionnaire].questionnaire.section.questions[
        idQuestion
      ].title = question.title;
    },
    addQuestionnaireTitle: (state, action) => {
      const data = action.payload;
      let newQuestionnaire = { ...defaultQuestionnaire };

      newQuestionnaire = {
        questionnaire: {
          ...newQuestionnaire.questionnaire,
          title: data.title,
        },
      };

      state.surveyState.push({ ...newQuestionnaire });
    },
    updateOption: (state, action) => {
      const {
        idQuestion, idOption, newOption, indexQuestionnaire,
      } = action.payload;
      state.surveyState[indexQuestionnaire].questionnaire.section.questions[idQuestion].options[
        idOption
      ].title = newOption;
    },
    updateQuestionTitle: (state, action) => {
      const { idQuestion, newTitle, indexQuestionnaire } = action.payload;
      state.surveyState[indexQuestionnaire].questionnaire.section.questions[idQuestion]
        .title = newTitle;
    },
  },
});

export default survey.reducer;

export const {
  addQuestion,
  addOption,
  deleteOptionSurvey,
  saveQuestionSurvey,
  addQuestionnaireTitle,
  updateOption,
  updateQuestionTitle,
  deleteQuestion,
} = survey.actions;
