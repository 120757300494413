const haccp = {
  id: 'haccp',
  title: 'HACCP',
  type: 'group',
  children: [
    {
      id: 'haccp-item',
      title: 'HACCP推進機能',
      type: 'item',
      url: '/',
      breadcrumbs: false,
    },
  ],
};

export default haccp;
