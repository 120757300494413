import { PlusCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';

const icons = {
  PlusCircleOutlined,
  UnorderedListOutlined,
};

const admin = {
  id: 'group-admins',
  title: 'Admins Managment',
  type: 'group',
  children: [
    {
      id: 'admins-list',
      title: 'List of admins',
      type: 'item',
      url: '/',
      icon: icons.UnorderedListOutlined,
      breadcrumbs: false,
    },
    {
      id: 'admins-create',
      title: 'Create a new admin',
      type: 'item',
      url: '/',
      icon: icons.PlusCircleOutlined,
      breadcrumbs: false,
    },
  ],
};

export default admin;
