import { useRoutes } from 'react-router-dom';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import JSCookie from 'js-cookie';

export default function ThemeRoutes() {
  const token = JSCookie.get('token');
  let getProfile;
  if (token) {
    getProfile = JSON.parse(JSCookie.get('profile'));
  }
  const roleProfile = getProfile?.role_id;
  return useRoutes([MainRoutes(token, roleProfile), LoginRoutes(token)]);
}
