import React, { lazy } from 'react';
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout/index';
import { Navigate } from 'react-router-dom';

const Statistic = Loadable(lazy(() => import('../pages/statistic_questionnaire/Statistic_Questionnaire')));
const SurveyList = Loadable(lazy(() => import('../pages/survey/SurveyList')));
const Survey = Loadable(lazy(() => import('../pages/survey/Survey')));
const ListResponses = Loadable(lazy(() => import('../pages/response-survey/ListResponses')));
const DetailResponse = Loadable(lazy(() => import('../pages/response-survey/DetailResponse')));
const EmailQR = Loadable(lazy(() => import('../pages/email_qr/EmailQR')));
const Selfie = Loadable(lazy(() => import('../pages/Demo')));
const ListAccount = Loadable(lazy(() => import('../pages/account/ListAccount')));
const CreateAndEditAccount = Loadable(lazy(() => import('../pages/account/CreateAndEditAccount')));
const ListStore = Loadable(lazy(() => import('../pages/store/ListStore')));
const CreateAndEditStore = Loadable(lazy(() => import('../pages/store/CreateAndEditStore')));
const ListCompany = Loadable(lazy(() => import('../pages/company/ListCompany')));
const CreateAndEditCompany = Loadable(lazy(() => import('../pages/company/CreateAndEditCompany')));
const ListStaff = Loadable(lazy(() => import('../pages/staff/ListStaff')));
const CreateAndEditStaff = Loadable(lazy(() => import('../pages/staff/CreateAndEditStaff')));
const ListMasterSurvey = Loadable(lazy(() => import('../pages/master_survey/ListMasterSurvey')));
const CreateAndEditMaster = Loadable(lazy(() => import('../pages/master_survey/CreateAndEditMaster')));
const ListGroup = Loadable(lazy(() => import('../pages/group/ListGroup')));
const CreateAndEditGroup = Loadable(lazy(() => import('../pages/group/CreateAndEditGroup')));
const QRcodeGroup = Loadable(lazy(() => import('../pages/group/QRcode')));
const PageNotFound = Loadable(lazy(() => import('../pages/authentication/Error404')));
const MainRoutes = (isLogin, roleProfile) => ({
  path: '/',
  element: isLogin ? <MainLayout /> : <Navigate to="/login" />,
  children: [
    {
      path: 'survey',
      children: [
        {
          path: 'list',
          element: <SurveyList />,
        },
        {
          path: 'create/:id',
          element: <Survey />,
        },
        {
          path: 'edit/:id',
          element: <Survey />,
        },
      ],
    },
    {
      path: 'account',
      children: [
        {
          path: 'list',
          element: roleProfile === 1 || roleProfile === 2 ? <ListAccount /> : <Navigate to="/" />,
        },
        {
          path: 'create',
          element: roleProfile === 1 || roleProfile === 2 ? <CreateAndEditAccount /> : <Navigate to="/" />,
        },
        {
          path: 'edit/:id',
          element: roleProfile === 1 || roleProfile === 2 ? <CreateAndEditAccount /> : <Navigate to="/" />,
        },
      ],
    },
    {
      path: 'company',
      children: [
        {
          path: 'list',
          element: roleProfile === 1 ? <ListCompany /> : <Navigate to="/" />,
        },
        {
          path: 'create',
          element: roleProfile === 1 ? <CreateAndEditCompany /> : <Navigate to="/" />,
        },
        {
          path: 'edit/:id',
          element: roleProfile === 1 ? <CreateAndEditCompany /> : <Navigate to="/" />,
        },
      ],
    },
    {
      path: 'store',
      children: [
        {
          path: 'list',
          element: roleProfile === 1 || roleProfile === 2 ? <ListStore /> : <Navigate to="/" />,
        },
        {
          path: 'create',
          element: roleProfile === 1 || roleProfile === 2 ? <CreateAndEditStore /> : <Navigate to="/" />,
        },
        {
          path: 'edit/:id',
          element: roleProfile === 1 || roleProfile === 2 ? <CreateAndEditStore /> : <Navigate to="/" />,
        },
      ],
    },
    {
      path: 'staff',
      children: [
        {
          path: 'list',
          element: <ListStaff />,
        },
        {
          path: 'create',
          element: <CreateAndEditStaff />,
        },
        {
          path: 'edit/:id',
          element: <CreateAndEditStaff />,
        },
      ],
    },
    {
      path: 'mastersurvey',
      children: [
        {
          path: 'list',
          element: roleProfile === 1 ? <ListMasterSurvey /> : <Navigate to="/" />,
        },
        {
          path: 'create/:id',
          element: roleProfile === 1 ? <CreateAndEditMaster /> : <Navigate to="/" />,
        },
        {
          path: 'edit/:id',
          element: roleProfile === 1 ? <CreateAndEditMaster /> : <Navigate to="/" />,
        },
      ],
    },
    {
      path: 'questionnaire/groups',
      children: [
        {
          path: 'list',
          element: <ListGroup />,
        },
        {
          path: 'create',
          element: <CreateAndEditGroup />,
        },
        {
          path: 'edit/:id',
          element: <CreateAndEditGroup />,
        },
      ],
    },
    {
      path: 'survey/response/:id',
      element: <ListResponses />,
    },
    {
      path: 'survey/response/:id/answers/:idAnswer',
      element: <DetailResponse />,
    },
    {
      path: 'survey/statistic/:id',
      element: <Statistic />,
    },
    {
      path: 'survey/email-qr/:code',
      element: <EmailQR />,
    },
    {
      path: 'questionnaire/groups/:code',
      element: <QRcodeGroup />,
    },
    {
      path: 'picture',
      element: <Selfie />,
    },
    {
      path: 'error/404',
      element: <PageNotFound />,
    },
  ],
});

export default MainRoutes;
