import React from 'react';
import Routes from './routes/index';
import ThemeCustomization from './themes';
import ScrollTop from './components/ScrollTop';
import Toast from './components/toast/Toast';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ThemeCustomization>
      <ScrollTop>
        <Toast />
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  );
}

export default App;
