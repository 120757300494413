import { PlusCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';

const icons = {
  PlusCircleOutlined,
  UnorderedListOutlined,
};

const stores = {
  id: 'group-stores',
  title: 'Stores Managment',
  type: 'group',
  children: [
    {
      id: 'stores-list',
      title: 'List of stores',
      type: 'item',
      url: '/',
      icon: icons.UnorderedListOutlined,
      breadcrumbs: false,
    },
    {
      id: 'stores-create',
      title: 'Create a new store',
      type: 'item',
      url: '/',
      icon: icons.PlusCircleOutlined,
      breadcrumbs: false,
    },
  ],
};

export default stores;
