import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link, useLocation } from 'react-router-dom';
import menuSuperAdmin from './menuSuperAdmin.json';
import menuCompanyAdmin from './menuCompanyAdmin.json';
import menuStoreAdmin from './menuStoreAdmin.json';
import './style.scss';
import JSCookie from 'js-cookie';

function SidebarMenu() {
  const location = useLocation();
  const getProfile = JSON.parse(JSCookie.get('profile'));
  const roleAccount = getProfile.role_id;
  const { pathname } = location;
  const isActive = (url) => {
    const { pathname } = location;
    if (pathname !== '/' && url === '/') return false;
    return !!url && pathname.includes(url);
  };

  const openSubMenus = (listMenus) => {
    const { pathname } = location;
    const indexMenuOpen = listMenus.findIndex((menu) => pathname.includes(menu.link));

    return indexMenuOpen > -1;
  };

  const renderMenus = (menus) => menus.map((menu) => {
    if (!menu.subMenus) {
      return (
        <MenuItem key={menu.id} active={isActive(menu.link) || pathname.includes(menu.childrenMenu)}>
          {menu.title}
          {menu.link && <Link to={menu.link} />}
        </MenuItem>
      );
    }
    return (
      <SubMenu key={menu.id} title={menu.title} defaultOpen={openSubMenus(menu.subMenus)}>
        {renderMenus(menu.subMenus)}
      </SubMenu>
    );
  });
  return (
    <ProSidebar>
      <SidebarContent>
        { roleAccount === 1 && <Menu>{renderMenus(menuSuperAdmin)}</Menu> }
        { roleAccount === 2 && <Menu>{renderMenus(menuCompanyAdmin)}</Menu> }
        { roleAccount === 3 && <Menu>{renderMenus(menuStoreAdmin)}</Menu> }
      </SidebarContent>
    </ProSidebar>
  );
}

export default SidebarMenu;
