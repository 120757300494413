import survey from './survey';
import haccp from './haccp';
import roles from './roles';
import stores from './stores';
import admin from './admin';

const menuItems = {
  items: [survey, haccp, roles, stores, admin],
};

export default menuItems;
