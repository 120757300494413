import { PlusCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';

const icons = {
  PlusCircleOutlined,
  UnorderedListOutlined,
};

const survey = {
  id: 'group-survey',
  title: 'Questionaire Management',
  type: 'group',
  children: [
    {
      id: 'survey-list',
      title: 'List of questionaire',
      type: 'item',
      url: '/survey/list',
      icon: icons.UnorderedListOutlined,
      breadcrumbs: false,
    },
  ],
};

export default survey;
